
import * as React from "react"
import './events.scss'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "../components/hero"
import EventContainerNew from '../components/event-container'
import ContactSection from "../components/contact-section"
import RightArrow from '../images/icons/arrow-events.svg';
import LeftArrow from '../images/icons/arrow-events-left.svg';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useViewport } from '../hooks/useViewPort'
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image"



function EventsPage() {
  const data = useStaticQuery(
    graphql`
        query KoepleEvents {
          eventsCategories: allWordpressWpKoeEventcategory {
            edges {
              node {
                name
                slug
                wordpress_id
              }
            }
          }
          eventsBannerDesktop: wordpressWpKoeEvent(
            title: {eq: "first event"}
          ) {
            acf {
              koe_header_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          eventsBannerMobile: wordpressWpKoeEvent(
            title: {eq: "first event"}
          ) {
            acf {
              koe_header_image_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          updatableBannerEvent: wordpressPage(title: {regex: "/.*Events.*/"}) {
            id
            childWordPressAcfBlockImageText {
              button_link
              button_text
              header
              text
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          events: allWordpressWpKoeEvent(sort: {fields: acf___koe_start_date, order: ASC}) {
            edges {
              node {
                koe_eventcategory
                acf {
                  koe_all_day_event
                  koe_button_link
                  koe_button_text
                  koe_category
                  koe_end_time
                  koe_event_name
                  koe_header_text
                  koe_start_date(formatString: "YYYY-MM-DD")
                  koe_start_time
                  koe_teaser_text
                  koe_teaser_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
                }
                id
                title
              }
            }
          }
        }
    `
  )

  const sources = [
    data.eventsBannerMobile.acf.koe_header_image_mobile.localFile.childImageSharp.gatsbyImageData,
    {
      ...data.eventsBannerDesktop.acf.koe_header_image.localFile.childImageSharp.gatsbyImageData,
      media: `(min-width: 426px)`,
    },
  ]
  const wpEvents = data.events.edges.filter(event => event.node.title !== "first event");
  const categories = data.eventsCategories
  const categoriesIds = data.eventsCategories.edges.map(category => category.node.wordpress_id)
  const [startDate, setStartDate] = React.useState(new Date());
  const [category, setCategory] = React.useState(categoriesIds)
  const [activeCategory, setActiveCategory] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [slideIndex, setSlideIndex] = React.useState(0);
  const sliderRef = React.useRef();


  const { width } = useViewport();
  const breakpointMobile = width < 426;


  const handleCategoryChange = (e) => {
    if (e.target.value !== categoriesIds) {
      const currentCategory = Number(e.target.value);
      setCategory(currentCategory);
      setActiveCategory(currentCategory)
    } else {
      setCategory(categoriesIds);
    }
  };




  function formatDate(unformattedDate) {
    return moment(unformattedDate).format('YYYY-MM-DD')
  }

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setStartDate(formattedDate);
  };

  const handlePreaviousDay = (e) => {
    e.preventDefault()
    setStartDate(prevDate => {
      let date = new Date(prevDate);
      let prevDay = date.setDate(date.getDate() - 1)
      return formatDate(prevDay);
    });
  };

  const handleNextDay = (e) => {
    e.preventDefault()
    setStartDate(prevDate => {
      let date = new Date(prevDate);
      let nextDay = date.setDate(date.getDate() + 1);
      return formatDate(nextDay);
    });
  };

  const DropdownMenuMobile = () => {

    const onClick = () => setIsActive(!isActive);

    return (
      <div className="menu-container">
        <button onClick={onClick} className="menu-trigger">Filter</button>
        <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
          <ul>
            <li><label className={!activeCategory ? "active" : null}><input className="radio-category" type="radio" name="category" value={[categoriesIds]} id={0}
              onClick={handleCategoryChange}
            />Alle</label></li>
            {
              categories.edges.map((category) => (
                <li key={category.node.wordpress_id}><label className={category.node.wordpress_id === activeCategory ? "active" : null}><input className="radio-category" type="radio" name="category" value={category.node.wordpress_id} id={category.node.wordpress_id}
                  onChange={handleCategoryChange}
                />{category.node.name}</label></li>
              ))
            }
          </ul>
        </nav>
      </div>
    );
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    draggable: true,
    beforeChange: (current, next) => setSlideIndex(next)

  };


  const AnimationsContainerTag = () => {
    return (
      <>
        <Layout>
          <Seo title="Events" />
          <Hero image={sources} alt={"Events Banner"} />
          <section className='outer-events-container'>
            <div className='inner-events-container'>
              <div className='updatable-banner-event'>
                <div className="banner-event-card">
                <div className='img-wrapper'>
                  <GatsbyImage className='banner-event-bg' image={data.updatableBannerEvent.childWordPressAcfBlockImageText.image.localFile.childImageSharp.gatsbyImageData} alt={data.updatableBannerEvent.childWordPressAcfBlockImageText.title} />
                </div>
                  <div className="banner-event-card-text">
                    <h3>{data.updatableBannerEvent.childWordPressAcfBlockImageText.header}</h3>
                    <p>{data.updatableBannerEvent.childWordPressAcfBlockImageText.text}</p>
                    <div className='cta-container'>
                        <button><a href={data.updatableBannerEvent.childWordPressAcfBlockImageText.button_link}>{data.updatableBannerEvent.childWordPressAcfBlockImageText.button_text}<RightArrow className='arrow' /></a></button>
                    </div>
                  </div>
                </div>              
              </div>
              <div className="top-selection-container">
                <div className='date-picker-container'>
                  <div className='left-arrow' onClick={handlePreaviousDay} onKeyDown={handlePreaviousDay} role="button" tabIndex={0} aria-label="preavious day"
                  ><LeftArrow /></div>
                  <DatePicker
                    wrapperClassName="datePicker"
                    selected={Date.parse(startDate)}
                    onChange={handleDateChange}
                    dateFormat="dd MMM yy"
                  />
                  <div className='right-arrow' onClick={handleNextDay} onKeyDown={handleNextDay} role="button" tabIndex={0} aria-label="next day"
                  ><RightArrow /></div>
                </div>
                {!breakpointMobile ?
                  <form className='events-categories-container'>
                    <label className={!activeCategory ? "active" : null}><input className="radio-category" type="radio" name="category" value={[categoriesIds]} id={0}
                      onClick={handleCategoryChange}
                    />
                        Alle
                    </label>
                    {
                      categories.edges.map((category) => (
                        <label key={category.node.wordpress_id} className={category.node.wordpress_id === activeCategory ? "active" : null}><input className="radio-category" type="radio" name="category" value={category.node.wordpress_id} id={category.node.wordpress_id}
                          onChange={handleCategoryChange}
                        />
                          {category.node.name}
                        </label>
                      ))
                    }
                  </form>
                  :
                  DropdownMenuMobile()
                }
              </div>
              {!breakpointMobile ?
                <>
                  <div className="carousel-container">
                    <h2 className="no-events">Er zijn geen events vandaag. Probeer een andere datum</h2>
                    <div className="progressbar-container">
                      <input
                        onChange={e => sliderRef.current.slickGoTo(e.target.value)}
                        value={slideIndex}
                        className="progress-bar"
                        type="range"
                        min={0}
                        max={3}
                      />
                    </div>
                    <Slider ref={sliderRef} {...settings} className="events-container">
                      {(formatDate(startDate) === formatDate(new Date())) ?
                        wpEvents.map((event, index) => (
                          <EventContainerNew event={event.node} key={index} />
                        ))
                        :
                        wpEvents.filter(event => {
                          const startEvtDate = event.node.acf.koe_start_date
                          const selectedDate = startDate
                          const eventCategory = Number(event.node.koe_eventcategory)
                          return selectedDate === startEvtDate || category === eventCategory
                        }).map((filteredEvent, index) => (
                          <EventContainerNew event={filteredEvent.node} key={index} />
                        ))
                      }
                    </Slider>
                  </div>
                </>
                :
                <>
                  <div className="carousel-container">
                    <h2 className="no-events">Er zijn geen events vandaag. Probeer een andere datum</h2>
                    <div className="events-container">
                      {(formatDate(startDate) === formatDate(new Date())) ?
                        wpEvents.map((event, index) => (
                          <EventContainerNew event={event.node} key={index} />
                        ))
                        :
                        wpEvents.filter(event => {
                          const startEvtDate = event.node.acf.koe_start_date
                          const selectedDate = startDate
                          const eventCategory = Number(event.node.koe_eventcategory)
                          return selectedDate === startEvtDate || category === eventCategory
                        }).map((filteredEvent, index) => (
                          <EventContainerNew event={filteredEvent.node} key={index} />
                        ))
                      }
                    </div>
                  </div>
                </>
              }
            </div>
          </section>
          {
            breakpointMobile ?
              <ContactSection />
              :
              null
          }
        </Layout>
      </>
    );
  }




  return (
    <>
      {AnimationsContainerTag()}
    </>
  )
}



export default EventsPage

import * as React from "react"
import './event-container.scss'
import { GatsbyImage } from "gatsby-plugin-image"
import RightArrow from '../images/icons/arrow-events.svg';
import Tilt from 'react-parallax-tilt';
import moment from "moment";
import { useViewport } from '../hooks/useViewPort'

const EventContainer = ({ event, index }) => {
    console.log("this is the start date from graphql")
    console.log(new Date(event.acf.koe_start_date))
    console.log("this is the start date from function")
    console.log(new Date())
    console.log("this is the filter")
    console.log(new Date() >= new Date(event.acf.koe_start_date))
    const { width } = useViewport();
    const breakpointMobile = width < 426;
    const EventTime = () => {
        return (
            <>
                {
                    event.acf.koe_end_time !== "" ?
                        [event.acf.koe_start_time, event.acf.koe_end_time].join(' - ')
                        :
                        event.acf.koe_start_time
                }
            </>
        )
    }
    return (
        <div>
            {!breakpointMobile ?
                <a href={event.acf.koe_button_link !== "" ? event.acf.koe_button_link : null} key={index} className="event-card">
                    <Tilt
                        tiltMaxAngleX={10}
                        tiltMaxAngleY={10}
                    >
                        <div
                            className='top-caption'
                        >
                            <p>
                                {moment(event.acf.koe_start_date).format("D MMM")}
                            </p>
                            <p>
                                {event.acf.koe_all_day_event ?
                                    "ALL-DAY"
                                    :
                                    <EventTime />
                                }
                            </p>
                        </div>
                        <div className='img-wrapper'>
                            <GatsbyImage image={event.acf.koe_teaser_image.localFile.childImageSharp.gatsbyImageData} alt={event.acf.koe_teaser_text} />
                        </div>
                    </Tilt>
                    <div className='bottom-caption-container-event'>
                        <p>{event.acf.koe_event_name}</p>
                    </div>
                    <div className='event-content'
                        dangerouslySetInnerHTML={{ __html: event.acf.koe_teaser_text }}
                    >
                    </div>
                    {
                        event.acf.koe_button_link !== "" ?
                            <div className='cta-container'>
                                <button><a href={event.acf.koe_button_link}>{event.acf.koe_button_text}<RightArrow className='arrow' /></a></button>
                            </div>
                            :
                            null
                    }

                </a>
                :
                <a href={event.acf.koe_button_link} key={index} className='event-card'>
                    <div
                        className='top-caption'
                    >
                        <p>
                            {moment(event.acf.koe_start_date).format("D MMM")}
                        </p>
                        <p>
                            {event.acf.koe_all_day_event ?
                                "ALL-DAY"
                                :
                                <EventTime />
                            }
                        </p>
                    </div>
                    <div className='img-wrapper'>
                        <GatsbyImage image={event.acf.koe_teaser_image.localFile.childImageSharp.gatsbyImageData} alt={event.acf.koe_teaser_text} />
                    </div>
                    <div className='bottom-caption-container-event'>
                        <p>{event.acf.koe_event_name}</p>
                    </div>
                    <div className='event-content'
                        dangerouslySetInnerHTML={{ __html: event.acf.koe_teaser_text }}
                    >
                    </div>
                    { 
                        event.acf.koe_button_link !== "" ?                          
                            <div className='cta-container'>
                                <button><a href={event.acf.koe_button_link}>{event.acf.koe_button_text}<RightArrow className='arrow' /></a></button>
                            </div>
                            :
                            null
                    }
                </a>
            }
        </div>
    )
}

export default EventContainer